// Margins API
// ===========

// Margin Output
// -------------
// - $before  : <length>
// - $after   : <length>
// - [$flow]  : ltr | rtl
@mixin margin-output(
  $before,
  $after,
  $flow: map-get($susy-defaults, flow)
) {
  $to: to($flow);
  $from: from($flow);

  $output: (
    margin-#{$from}: $before,
    margin-#{$to}: $after,
  );

  @include output($output);
}
