// Isolation Syntax
// ================


// Isolate [Mixin]
// ---------------
// Set isolation as an override.
// - $location: <span>
@mixin isolate(
  $isolate: 1
) {
  $inspect: $isolate;

  $output: (
    push: isolate($isolate),
    flow: susy-get(flow, $isolate),
  );

  @include susy-inspect(isolate, $inspect);
  @include isolate-output($output...);
}


// Isolate [function]
// ------------------
// Return an isolation offset width.
// - $location: <span>
@function isolate(
  $isolate: 1
) {
  $isolate: parse-span($isolate);
  $isolation: susy-get(span, $isolate);

  @if $isolation and not(get-location($isolate)) {
    $new: (
      span: null,
      location: $isolation,
    );
    $isolate: map-merge($isolate, $new);
  }

  @return get-isolation($isolate);
}


// Get Isolation
// -------------
// Return the isolation offset width
// - $input: <map>
@function get-isolation(
  $input
) {
  $location   : get-location($input);
  $columns    : susy-get(columns, $input);
  $width      : null;

  @if type-of($location) == number and not(unitless($location)) {
    $width: $location;
  } @else if $location {
    $push: $location - 1;
    @if $push > 0 {
      $push: map-merge($input, (
        span: $push,
        location: 1,
        spread: wide,
      ));
      $width: get-span-width($push);
    }
  }

  @if susy-get(gutter-position, $input) == split {
    $width: if($width == null, gutters($input), $width + gutters($input));
  }

  @return $width or 0;
}
