// Float Ends API
// ==============

// Susy End Defaults
// -----------------
// - PRIVATE
@include susy-defaults((
  last-flow: to,
));

// Float Last
// ----------
// - [$flow]  : ltr | rtl
@mixin float-last(
  $flow: map-get($susy-defaults, flow),
  $last-flow: map-get($susy-defaults, last-flow),
  $margin: 0
) {
  $to: to($flow);

  $output: (
    float: if($last-flow == to, $to, null),
    margin-#{$to}: $margin,
  );

  @include output($output);
}

// Float First
// -----------
// - [$flow]  : ltr | rtl
@mixin float-first(
  $flow: map-get($susy-defaults, flow)
) {
  $output: (
    margin-#{from($flow)}: 0,
  );

  @include output($output);
}
