/*=========================================
=            left-sidebar SCSS            =
=========================================*/

.left-sidebar-wrapper{
	@include span(2.5 of 12);
	width: 250px;
	background: #082644;
	opacity: 0.9;
	height: 100%;
	z-index: 1;
	position: fixed;
	top: 50px;
	padding: 0;
	overflow-y: auto;
	
	//transition: 0.7s;
	// &::-webkit-scrollbar-track
	// {
	// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	// 	background-color: #4E2470;
	// 	border-radius: 10px;
	// }
	// &::-webkit-scrollbar
	// {
	// 	width: 10px;
	// 	background-color: #851717;
	// }
	// &::-webkit-scrollbar-thumb
	// {
	// 	border-radius: 10px;
	// 	background-color: #5C86E0;
	// 	background-image: -webkit-gradient(linear,
	// 									   40% 0%,
	// 									   75% 84%,
	// 									   from(#4D9C41),
	// 									   to(#19911D),
	// 									   color-stop(.6,#54DE5D))
	// }

	


}


ul.sidebar-nav.sidebar-main {
	width: 100%;
	> li  {
		> a {
			border-radius: 0px;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			-ms-border-radius: 0px;
			color: #fff;
			&:hover, &:active, &:focus {
				color: rgb(0, 0, 0);
			}
		}
		&:last-child {
			padding-bottom: 100px;
		}
	}
	ul{
		li a {
			padding-left: 40px;
			color: whitesmoke;
			&:hover {
				color: black;
			}
		}
	}
	.active {

		a {
			background: #6d8094;
		}
	}
}

/*=====  End of left-sidebar SCSS  ======*/
