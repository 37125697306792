.agent {
    padding-bottom: 60px;
    .agent-sidebar {
        padding-top: 20px;
    }
    .agent-content {
        padding-top: 20px;

    }
    form {
        #return-date {
            display: none;
        }
        p {
            display: inline-block;
            font-size: 16px;
        }
    }
    .thumbnail {
        border: 0px;
        text-align: center;
    }
}

.affix {
    top: 0;
    width: 84.5%;
    z-index: 99999;
    border-bottom: 1px solid dodgerblue;
    table {
        margin-bottom: 0px;
        tr > td,tr > th {
            padding: 0px 10px;
            transition: 0.2s;
        }
    }
}
.vessel-details-table {
    
        margin-bottom: 0px;
        tr > td,tr > th {
            padding: 10px 5px;
            transition: 0.2s;
        }
   
}

.affix + .stick {
    padding-top: 230px;
}