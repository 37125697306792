.navbar {
    margin-bottom: 0px;
    border-radius: 0;
    .navbar-brand {
        background: transparent;
        color: #f7f7f7;
        &:hover {
            color: #ccc !important;
        }
    }
    &.navbar-inverse {
        background: #0267a4 !important;
        border-color: #0267a4 !important;
    }
    .navbar-nav {
        a {
            color: #f7f7f7 !important;
            &:hover {
                color: #ccc;
            }
        }
        .dropdown {
            &.open {
                a.dropdown-toggle {
                    background-color: darken(#0267a4,10%) !important;
                }
                
            }
        }
        .dropdown-menu {
            li {
                a {
                    color: #444 !important;
                    padding: 15px;
                    &:hover {
                        color: #000;
                        background: lighten(#0267a4,15%);

                    }
                }

            }
            
        }
    }
}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
    background: #0267a4 !important;
}
.modal-image {
    margin-top: 40px;
    margin-bottom: 35px;
    img {
        border-radius: 100px; 
    }
}
// @media (min-width: 768px){
    // .modal-sm {
        //     width: 365px;
        // }
// }