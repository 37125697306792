// Debugging
// =========

// Susy Inspect
// ------------
// Output arguments passed to a inspect.
// - $mixin  : <susy mixin>
// - $inspec   : <mixin arguments>

@mixin susy-inspect($mixin, $inspect...) {
  $show: false;

  @each $item in $inspect {
    @if index($item, inspect) {
      $show: true;
    }
  }

  @if $show or susy-get(debug inspect) {
    -susy-#{$mixin}: inspect($inspect);
  }
}
