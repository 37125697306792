.payment-methods {
    padding-bottom: 60px;
    .paypal {
        max-width: 400px;
        margin: 20px 10px;
    }
    a.btn-image {
        display: inline-block;
        border: 1px solid gray;
        
        img {
            width: 100px;
            height: auto;
        }
        &:hover {
            box-shadow: $box-shadow;
        }
    }
}