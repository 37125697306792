/*===================================
=            header SCSS            =
===================================*/

.top-navbar {
	width: 100%;
	//@include span(12 of 12);
	background: #ededed;
	height: 50px;
	color: #fff;
	box-shadow: 0px 0px 5px #999;
	position: fixed;
	top: 0;
	z-index: 1;
}

.navbar-brand {
	background: #2A3F54;
	width: 250px;
	height: 50px;
	h4 {
		padding: 4px;
		margin: 0;
		font-weight: 400;
		text-align: center;
	}
}

.navbar-content {
	a {
		span.glyphicon-menu-hamburger {
			float:left;
			font-size: 30px;
			color: #6d8094;
			padding: 8px 8px;
		}
	}
}

.profile-section {
	margin-right: 50px;
	.navbar-right ul.dropdown-menu {
		 left: auto;
		 right: 0;
	}
}
/*=====  End of header SCSS  ======*/
