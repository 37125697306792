

@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../bower_components/susy/sass/susy";

@import "layouts/base";
@import "layouts/perfect-scrollbar";
@import "layouts/main/header";
@import "layouts/main/footer";

@import "layouts/pages/index";
@import "layouts/pages/vessel";
@import "layouts/pages/passenger-details";
@import "layouts/pages/itinerary-report";
@import "layouts/pages/payment-methods";
@import "layouts/pages/agent";
@import "layouts/pages/cargo-home";
@import "layouts/pages/ticket";


/*=========================================
=            admin scss import            =
=========================================*/

@import "layouts/main/admin/header";
@import "layouts/main/admin/main";
@import "layouts/main/admin/footer";
@import "layouts/main/admin/left-sidebar";
@import "layouts/main/admin/login-page";

/*=====  End of admin scss import  ======*/

