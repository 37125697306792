.cargo {
    > .container {
        background: #f9f9f9;
    }
    .cargo-sidebar {
        padding-top: 2em;
        border-right: 1px solid #eee;
        .thumbnail {
            background: transparent;
            border: 0px;
            text-align: center;
        }
    }

    .cargo-content {
        padding-bottom: 3em;
        padding-top: 2em;
        .form-box {
            font-family: 'Roboto', sans-serif !important;
            
            form.cargo-form fieldset {
                display: none;

                .form-header {
                    padding: 1em 2.5em 1.5em 2.5em;
                    background: #337ab7;
                    border-radius: 4px 4px 0 0;
                    h2 {
                        color: #fff;
                        font-weight: 300;
                    }
                }
                .form-body {
                    padding: 2.5em 2.5em 2.0em 2.5em;
                    background: #eee;
                    border-radius: 0 0 4px 4px;

                    .input-error {
                        border-color: #d03e3e;
                        color: #d03e3e;
                    }

                    .form-control:focus {
                        border-color: #8b8b8b;
                        box-shadow: none;
                        transition: all .9s;
                    }

                    button.btn {
                        min-width: 105px;
                        background: #337ab7;
                        height: 3em;
                        color: #fff;
                        transition: all .3s;

                        &:hover, &:active, &:focus {
                            opacity: 0.8;
                            box-shadow: none;
                            outline: 0;
                        }
                        &.btn-previous {
                            background: darken(#337ab7,10%);
                        }
                    }            
                }
            }
        }
    }
}