.cargo-ticket {
    padding-top: 10px;
    padding-bottom: 60px;
    table.payment td {
        text-align: right;
    }
    @media (min-width: 992px) {
        .col-md-4 .qr-code img{
            float: right;
        }
    }
}