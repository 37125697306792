
.booking {
    padding-bottom: 60px;
    h2 {
        color: #0267a4;
        font-weight: 600;
        opacity: 0.6;
    }
    .booking-header {
        background: url(../images/bg01.jpg) no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        position: relative;
        color: white;
        h1 {
            
        text-shadow: 3px 3px 4px #000;
        }
        z-index: 1;
        &:after {
            content: "";
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .welcome-note {
            color: white;
        }
        .booking-form {
            background-color: rgba(0, 0, 0, 0.6);
            padding: 20px 15px;
            box-shadow: $box-shadow;
            #return-date {
                display: none;
            }
            .form-group {
                margin-bottom: 5px;
                p {
                    padding-top: 7px;
                    margin-bottom: 0;
                }
                label {
                    font-weight: normal;
                }
                .select {
                    select {
                        background-color: rgba(0, 0, 0, 0.35);
                        color: whitesmoke;

                        option {
                            color: rgb(255, 255, 255);
                            background: rgba(0, 0, 0, 0.733);
                            
                            &[hidden] {
                                color: gray;
                            }

                        }
                    }
                    .col-sm-6 {
                        padding-left: 0.5px;
                        padding-right: 0.5px;
                    }
                }
                .date {
                    color: black;
                }
            }
            .form-control {
                padding-top: 5px;
            }
            input {
                background-color: rgba(0, 0, 0, 0.35);
                color: whitesmoke;
            }
            .form-horizontal .control-label {
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
}