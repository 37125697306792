// Float Container API
// ===================

// Float Container
// ---------------
// - [$width]         : <length>
// - [$justify]       : left | center | right
// - [$math]   : fluid | static
@mixin float-container(
  $width,
  $justify: auto auto,
  $property: max-width
) {
  @include susy-clearfix;
  @include container-output($width, $justify, $property);
}
