// Background Properties
// =====================

// Susy Background Image
// ---------------------
// Check for an existing support mixin, or provide a simple fallback.
// - $image: <background-image>
@mixin susy-background-image(
  $image
) {
  @if susy-support(background-image, (mixin: background-image), $warn: false) {
    @include background-image($image...);
  } @else {
    background-image: $image;
  }
}

// Susy Background Size
// ---------------------
// Check for an existing support mixin, or provide a simple fallback.
// - $image: <background-size>
@mixin susy-background-size(
  $size
) {
  @if susy-support(background-options, (mixin: background-size)) {
    @include background-size($size);
  } @else {
    background-size: $size;
  }
}

// Susy Background Origin
// ----------------------
// Check for an existing support mixin, or provide a simple fallback.
// - $image: <background-origin>
@mixin susy-background-origin(
  $origin
) {
  @if susy-support(background-options, (mixin: background-origin)) {
    @include background-origin($origin);
  } @else {
    background-origin: $origin;
  }
}

// Susy Background Clip
// --------------------
// Check for an existing support mixin, or provide a simple fallback.
// - $image: <background-clip>
@mixin susy-background-clip(
  $clip
) {
  @if susy-support(background-options, (mixin: background-clip)) {
    @include background-clip($clip);
  } @else {
    background-clip: $clip;
  }
}
