.passenger-details {
    padding-bottom: 60px;
    .topbar {
        margin: 10px auto;
        .btn {
            border-radius: 0px;
            padding: 3px 5px;
            background-color: #C0BCBC;
        }
    }

    .table {
        margin-bottom: 10px;
        .agent-details, .ticket-info {
            p {
                margin: 0;
            }
        }
        h4 {
            margin: 0;
        }
    }
    span.symbols {
        display: inline-block;
        font-size: 17px;
        margin-top: -5px;
    }
}
