html {
	height: 100%;
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
body {
	position: relative;
	margin: 0;
	padding-bottom: 6rem;
	min-height: 100%;
	font-family: "Helvetica Neue", Arial, sans-serif;
}
footer {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	background-color: #efefef;
	text-align: center;
}

$box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.68);

a:hover {
	text-decoration: none;
}
.title-decoration {
	border-bottom: 3px ridge darken($brand-primary, 10%);
}
.btn {
	&:hover {
		box-shadow: $box-shadow;
	}
}

.alert-danger,.alert-warning {
	padding: 3px 3px 3px 8px !important;
	

}
.alert {
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webit-border-radius: 0px;
	-ms-border-radius: 0px;
}

button {
	a, a:hover, a:focus, a:active {
		color: inherit;
		text-decoration: none;
	}
}
// .box-7 {
	//     border: 5px groove orange;
	//   }

	//   .box-8 {
		//     border: 5px ridge orange;
		//   }

		//   .box-9 {
			//     border: 5px inset orange;
			//   }

			//   .box-10 {
				//     border: 5px outset orange;
				//   }
				//   



				