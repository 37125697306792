/*=================================
=            main SASS            =
=================================*/
.admin {
	padding-left: 0px;
}
.main-content {
	//@include container(80em);
	padding: 25px 25px 25px 275px;
	margin-top: 25px;
}

.box {
	width: 100%;
	padding: 10px 17px 15px 17px;
	margin-bottom: 10px;
	margin-top: 20px;
	display: inline-block;
	background: #fff;
	border: 1px solid #E6E9ED;
	-webkit-column-break-inside: avoid;
	-moz-column-break-inside: avoid;
	column-break-inside: avoid;
	opacity: 1;
	transition: all .2s ease;
}
.box-title {
	border-bottom: 2px solid #E6E9ED;
	padding: 1px 5px 6px;
	margin-bottom: 10px;
	position: relative;
	h2 {
		margin: 0px 0 3px;
		display: block;
		font-size: 22px;
		em {
			font-size: 14px;
			padding-left: 10px;
			color: #6d8094;
		}
	}
	button,input {
		position: absolute;
		top: 0;
		right: 10px;
	}
}

.box-primary {
	border-top: 3px solid #337ab7;
}

.box-info {
	border-top: 3px solid #5bc0de;
}

.box-warning {
	border-top: 3px solid #f0ad4e;
}

.box-success {
	border-top: 3px solid #5cb85c;
}

.box-danger {
	border-top: 3px solid #d9534f;
}
.box-body {
	padding: 0 5px 6px;
	float: left;
	clear: both;
	margin-top: 5px;
	width: inherit;
}

.th-bg {
	background: #f7f7f7;
}
.th-name {
	@extend .th-bg;
	width: 30%;
}
.tab-pane {
	padding-top: 20px;
}
.bg-gray {
	color: #fff;
	background-color: #888;
}
.table-condensed > tbody > tr > td 
,.table-condensed > tbody > tr > th  {
	vertical-align: middle !important;
}

.nav-shrinked {
	min-height: 100%;
	width: 70px;
	padding: 0;
	z-index: 9999;
	position: absolute;
	font-size: 11px;
	text-align: center;
}


/////nav tabs style

.nav-tabs > li > a {
	margin-right: 2px;
	line-height: 1.42857;
	border: 1px solid transparent;
	border-radius: 0px 0px 0 0;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	color: #fff;
	background-color: #5cadd6;
	border: 1px solid transparent;
	border-bottom-color: transparent;
	cursor: default;
}
/*=====  End of main SASS  ======*/


/*=====================================
=            widget styles            =
=====================================*/

.tile-stats {
	position: relative;
	display: block;
	margin-bottom: 20px;
	border: 1px solid #E4E4E4;
	
	
	overflow: hidden;
	padding-bottom: 15px;
	-webkit-background-clip: padding-box;
	
	-moz-background-clip: padding;
	
	background: #FFF;
	transition: all .3s ease-in-out;
	color: #6d8094 ;
	border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	-ms-border-radius: 0px;
	box-shadow: 0px 3px 7px #999; 
	.icon {
		width: 20px;
		height: 20px;
		color: #BAB8B8;
		position: absolute;
		right: 53px;
		top: 22px;
		z-index: 1;
	}
	.count {
		font-size: 38px;
		font-weight: 700;
		line-height: 1.65857;
	}
	.count,  h3,  p {
		position: relative;
		margin: 0 0 0 10px;
		z-index: 5;
		padding: 0;

	}
	h3 {
		font-size: 20px;
	}
}

.tile-stats-info {
	border-left:10px solid #5bc0de;
}

.icon-lg {
	font-size: 15px !important;
}
.icon-x {
	font-size: 18px;
	padding: 5px;
}

ul.journey-freq {
	padding: 10px 5px;
	li {
		float: left;
		padding: 10px 10px;	
		&:first-child {
			padding: 0px !important;
		}
		&:last-child {
			padding-left: 125px;
		}
		
	}
}


.ui-checkbox {
	display: none;
}

.ui-checkbox + label {
	position: relative;
	padding-left: 25px;
	display: inline-block;
	font-size: 12px;
}

.ui-checkbox + label:before {
	background-color: #fff;
	border: 1px solid #30b0d7;    
	padding: 9px;
	border-radius: 3px;
	display: block;
	position: absolute;
	top: 0;
	left:0;
	content: "";
}



.ui-checkbox:checked + label:before {
	border: 1px solid #30b0d7;    
	color: #99a1a7;
}

.ui-checkbox.no-border:checked + label:before {
	border-color: transparent;
}



.ui-checkbox:checked + label:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: 1px;
	left: 4px;
	color: #30b0d7;
}

.ui-checkbox.green-tick:checked + label:after {
	color: #8cc63f;
}

.ui-radio {
	display: none;
}

.ui-radio + label{
	position: relative;
	padding-left: 20px;
	display: inline-block;
}
.ui-radio + label:before {
	background-color: #fff;
	border: 1px solid #30b0d7;
	border-radius: 50px;
	display: inline-block;
	position: absolute;
	content: ' ';
	width: 16px;
	height: 16px;
	top:0px;
	left:0px;
}

.ui-radio:checked + label:after {
	content: ' ';
	width: 12px;
	height: 12px;
	border-radius: 50px;
	position: absolute;
	background: #30b0d7;
	top: 3px;
	left: 3px;
}

.ui-radio:checked + label:before {
	color: #99a1a7;
	border: 1px solid #30b0d7;
}



/*=====  End of widget styles  ======*/


.text-vertical {
	transform: rotate(270deg);
}

.passenger-classification-list-table {
	font-size: 12px;
	tr > th {
		
	}
}

.table-noborder {
	tbody {
		tr {
			td,th {
				border: none;
			}
			label {
				font-weight: normal;
				cursor: pointer;
				&:hover {
					color: dodgerblue;
					
				}
			}
		}
	}
	thead {
		tr {
			th,td {
				border: none;
			}
		}
	}
	tfoot {
		tr {
			th,td {
				border: none;
			}
		}
	}
}

.flat-modal {
	.modal-content,.btn {
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-ms-border-radius: 0px;

	}

}

.panel-flat {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	-ms-border-radius: 0px;
}

ul.permistion-listing {
	list-style: none;
	padding-left: 0px;
	li {
		padding: 2px 0px 0px 0px;
	}
}
.module {
	.panel-body {
		padding: 5px 15px !important;
	}
}

.icon.icon-3x {
	font-size: 33px;
}

.required {
	color: red;
}


ul.manage-panel {
	
	li {
		padding: 10px;
		border-top: 2px solid #ddd;
		border-left: 2px solid #ddd;
		border-right: 2px solid #ddd;

		&:last-child {
			border-bottom: 2px solid #ddd;
		}
		a {
			width: 100%;
		}
	}
	
}

.hr {
	padding-top: 0px;
	padding-bottom: 0px;
	hr {
		margin-top: 0px;
		margin-bottom: 5px;
	}
}

.no-color {
	border-color: #fff;
}

.cin-btn-group {
	a {
		.cin {
			border: 3px solid #eee;
			padding: 15px;
			.icon {
				font-size: 50px;
			}
		}
	}
	
}